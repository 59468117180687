/* preview_body_desktop */
.preview_body_desktop {
  width: 592px;
}

.preview_body_tablet {
  width: 700px;
}

.preview_body_ipad {
  width: 704px;
}

.preview_body_iphone {
  width: 311px;
}

.preview_body_date {
  color: #70757a;
  line-height: 1.57;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.amp-simulator {
  width: 704px;
}
