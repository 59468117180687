@import "../../../../../../theme.css";

.table-details__title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.table-details__image {
  max-width: 100px;
}
