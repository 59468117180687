.reportContainer {
  background-color: #edf0f2;
  padding: 34px 39px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.statHeaders {
  height: 123px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 8px;
}
