/*!*AMP SECTION *!*/
.amp-carousel {
  width: 232px;
}

.amp-carousel__card-container {
  display: flex;
  padding: 0 0 0 8px;
}

.amp-carousel__card-container:first-child {
  padding-left: 0;
}

.qIYiue {
  display: flex;
}

[data-hveid] {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.VoEfsd {
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
}

.xE0xid {
  width: 100%;
}

.QCSNec {
  display: flex;
  flex-direction: column;
}

g-inner-card {
  background-color: #fff;
  border-radius: 8px;
  /*display: block;*/
  overflow: hidden;
}

a:any-link {
  /*color: -webkit-link;*/
  /*text-decoration: underline;*/
  /*cursor: auto;*/
}

.amp-carousel a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.amp-carousel a,
.w,
a.feGi6e {
  color: #1967d2;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.amp-carousel a:visited {
  color: #4b11a8;
}

/*Card Top*/
.amp-carousel__top-section {
  height: 42px;
}

/*Article Page Highlight*/
.MaNG8b {
  color: #3c4043;
  font-size: 14px;
  line-height: normal;
  padding: 13px 16px 0;
  position: relative;
}

.o5GQac {
  display: inline-block;
  vertical-align: middle;
  border-style: none;
  margin-right: 8px;
}

/*Favicon Image */
.rISBZc {
  display: block;
  border: 0;
}

.M4dUYb {
  position: relative;
}

img#dimg_22.rISBZc.zr758c.M4dUYb {
  height: 16px;
  width: 16px;
}

.amp-carousel :any-link img {
  -webkit-tap-highlight-color: inherit;
}

/*Page Name Besides Favicon*/
.WF4CUc {
  display: inline-block;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 134px;
}

/*AMP Icon*/
.uz9HCf {
  float: right;
}

.QzoJOb {
  margin-left: 12px;
  margin-right: 0;
}

.ZseVEf {
  background-size: 100% 100%;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-bottom: -2px;
  opacity: 0.46;
  margin-right: 6px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAABr0lEQVR4AbWWJYCUURhFD04Zi7hrLzgFd4nzV9x6wKHinmYb7g4zq71gIw2LWBnZ3Q8df/fh96Tn/t2HVIw4CVKk+fSFNCkSxInxW1pFkhLmoMRjVvFLmkEX5ocuZuBVPw5jv8hh+iEU5QEmuMK+prz7RN3dPMMEGQYzxpH/lGjzou5jgl7mAvOdZfcbF+jbm3MAbFZ7VX9SJnlL1D8UMyjLe+BrAYDb+jJUr59JrlNWRtcqX9GkrPCR4QBAf4qYJAkQoyQrbKKs8RiaEjEI0GvvQ1mLMC9xaBFFBaZS1TbMSwJSomg39erDF+TxpCCNOXjGQJTCvG6qn4ZPzkcxA61Tjhaf4KMj+6Q3XvW6Lopraa8IozRQxIi0a7NXorULc5JyHX/3F3q+0PsFYytVTaGgjz/AvCyiegE69IUsPxHNBMpa738i6tGWlzkAABjKe/+j9YeRHGVd9oWRnwe2ewDASp/L/UqoPQ5AmFeYZMavBP8dAJz0GWWDHQlzXApMdz4KYUfKICcxkKeOfGmQyrIPcgE9m+g/+kT812/Nr3+0kqzitxQjoKXh6xfor99nlEdFjyvH15gAAAAASUVORK5CYII=);
}

/*Article Image Section*/
.qIYiue .amp-carousel__image-section {
  position: relative;
}

.qV9w7d {
  overflow: hidden;
  position: relative;
}

.KNcnob {
  background-color: #eee;
  position: absolute;
  z-index: 0;
  margin: auto;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
}

g-img {
  display: block;
}

.rISBZc {
  display: block;
  border: 0;
}

.M4dUYb {
  position: relative;
}

/* Article Title Section */
.mRnBbe,
.amp-carousel__title-section {
  font-weight: medium;
  margin: 13px 16px 0;
  margin-bottom: 0.5em;
}

.QgUve {
  font-size: 16px;
  line-height: 1.25em;
}

.nDgy9d {
  display: -webkit-box;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  white-space: normal;
}

/* Article time since published section */
.amp-carousel__time-published-section {
  padding: 0 16px 11px;
}

.S1FAPd {
  color: #70757a;
  font-size: 14px;
  line-height: 1.4285714285714286em;
  font-size: 12px;
  line-height: 1.6666666666666667em;
  margin-bottom: 0;
  margin-top: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
