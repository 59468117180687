/* Icons */
.icon-wrapper {
  position: relative;
  display: inline-block;
  float: left;
  vertical-align: top;
  /*width: 129px;*/
  width: 120.8px;
  height: 40px;
  background-color: #e9e9e9;
  cursor: pointer;
}

.icon-wrapper:hover {
  background-color: #f5f5f5;
  color: #000 !important;
}
.icon-wrapper.imlinkchosen {
  background-color: #fff;
  border-top: 4px solid #bbbec3;
  border-bottom: 0px none;
  color: #000 !important;
}

.icon-wrapper:hover .icon-device,
.icon-wrapper.imlinkchosen .icon-device {
  background-position: 0 -100px;
}

/*imlinkchosen  */
.imlinkchosen {
  background-color: #fff;
  position: relative;
}

.imlinkchosen:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  border: 15px solid transparent;
  margin-left: -20px;
  z-index: 10;
  border-width: 12px 20px;
}

/* borderno */
.borderno {
  border-bottom: 4px none #fff;
}

.borderno:after {
  border-top-color: #fff;
}

/* borderyes */
.borderyes {
  border-top: 4px none #bbbec3;
}

.borderyes:after {
}

/* icon-device */
.icon-device {
  position: absolute;
  top: 12px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 43px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.icon-device.desktop {
  background-image: url("./../../../../assets/images/icon_desktop.svg");
  width: 60px;
}

.icon-device.tablet {
  background-image: url("./../../../../assets/images/icon_tablet.svg");
  width: 30px;
}

.icon-device.mobile {
  background-image: url("./../../../../assets/images/icon_mobile.svg");
  width: 21px;
}

.icon-device.imac {
  background-image: url("./../../../../assets/images/icon_imac.svg");
  width: 60px;
}

.icon-device.iphone {
  background-image: url("./../../../../assets/images/icon_iphone.svg");
  width: 21px;
}

/* device-name */
.device-name {
  margin-bottom: 0;
  position: absolute;
  bottom: 5px;
  top: 5px;
  width: 100%;
  text-align: center;
  font-family: Avenir;
  font-size: 16px;
  color: #010067;
}
