@import "../../theme.css";

/* Global */
.google-serp-tool * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.google-serp-tool {
  color: #000;
  font-family: "Retina Light", Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.google-serp-tool h1,
.google-serp-tool h2 {
  font-family: "Escrow", Times, serif;
  font-size: 1.5em;
}

.google-serp-tool h3 {
  font-size: 1rem;
  font-family: "Retina Medium", Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  letter-spacing: 1.5px;
}

.google-serp-tool .wrapper h2:nth-child(1),
.google-serp-tool .wrapper h3:nth-child(1) {
  margin-top: 0;
}

.google-serp-tool a {
  color: #000;
}

.wrapper h3:nth-child(1) {
  margin-top: 0;
}

p {
  line-height: 1.5rem;
}

.wrapper p:nth-last-child(1) {
  margin-bottom: 0;
}

/* Wrapper */
.wrapper {
  padding: 40px 0;
  width: 1055px;
  margin: 0 auto;
}

.wrapper.article-form-wrapper {
  padding-top: 1rem;
  padding-bottom: 3.1rem;
}

@media (max-width: 1160px) {
  .wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

/* preview_label */
.preview_label p {
  padding-right: 1em;
}

.preview_label p:first-child {
  font-size: 22px;
  height: 70px;
}

@media only screen and (max-width: 767px) {
  .r_a {
    text-align: center;
  }

  .preview_label p {
    display: inline-block;
  }

  .preview_label p:first-child {
    height: auto;
    margin: 0;
    display: block;
  }
}

/* clear-both */
.clear-both {
  clear: both;
}
