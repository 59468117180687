/* #preview */
@media only screen and (max-width: 480px) {
  #preview {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/*snippet preview*/
.snippet-preview {
  position: relative;
  width: 100%;
  height: auto;
  border: 1px solid #dde2e2;
  background-color: #fff;
  padding: 5px;
}

/* preview-render */
.preview-render {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.preview-render #preview_title,
.preview-render #preview_body {
  display: flex;
}

/* preview-wrapper */
.preview-wrapper {
  display: flex;
}

.preview-wrapper .inner {
  min-width: 145px;
}

/* status-title */
.status-title {
  padding-left: 10px;
  font-family: "Retina Medium", Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* a_tag_google */
.a_tag_google {
  color: rgb(26, 13, 171) !important;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 21.6px;
  text-align: left;
  text-decoration: none;
  overflow: hidden;
  display: block;
}

.a_tag_google:hover {
  text-decoration: underline;
}

.a_tag_google.preview_title_tablet,
.a_tag_google.preview_title_ipad,
.a_tag_google.preview_title_iphone {
  font-size: 16px;
  line-height: 20px;
}

.a_tag_google.preview_body_tablet,
.a_tag_google.preview_body_ipad,
.a_tag_google.preview_body_iphone {
  font-size: 13px;
}

/* url_google */
.url_google {
  color: rgb(0, 102, 33);
  font-family: arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  text-align: left;
  white-space: pre-wrap;
  text-decoration: none;
  padding-top: 3px;
  margin-bottom: 3px;
}

/* descr_google */

.descr_google {
  color: rgb(84, 84, 84);
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18.2px;
  text-align: left;
  word-wrap: break-word;
  margin-top: 0px;
  white-space: normal;
  text-decoration: none;
}

.ant-popover-arrow {
  background-color: #73cf00;
}
