@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Space+Mono&display=swap");
@import url("https://fonts.googleapis.com/css?family=Avenir");
body {
  margin: 0;
  padding: 0;
  font-family: "Space Mono", "Avenir";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Space Mono", monospace;
}

.reports-selector-checkbox .ant-checkbox-group-item {
  /* forcing checkbox item to be shown in a new line */
  display: block;
}

.url-input .ant-form-inline .ant-form-item-with-help {
  /* forcing input helper tip not to add bottom margin */
  margin-bottom: 0px;
}

.ant-radio {
  padding: none;
  margin: none;
  display: none;
}

.ant-radio-wrapper .ant-radio-wrapper-checked {
  padding: none;
  margin: none;
}

.ant-popover-inner-content {
  padding: 0;
  background-color: #aae8f0;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #aae8f0;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #aae8f0;
  -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #30c6d6;
  border-bottom-color: #30c6d6;
  border-left-color: transparent;
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

/* For Role Menu*/
.role-menu
  > .ant-popover
  .ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  -webkit-box-shadow: -2px -2px 5px rgba(73, 26, 202, 0.06);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #fd0a40;
  margin-top: 4px;
}

.ant-card-body {
  padding: 3px;
}

.ant-progress-outer {
  /* nothing */
}

.custom-progress-bar .ant-progress-inner {
  border-radius: 0px;
  border-style: solid;
  border-color: #010067;
  border-left: 0px;
  border-right: 0px;
  border-width: thin;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff;
  color: #000000;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  opacity: 0;
}

.ant-menu-item-active {
  color: #010067;
  background-color: rgba(255, 255, 255, 0.65);
}

.ant-menu-item:hover {
  color: #010067;
}

.ant-menu-item > a:hover {
  color: #010067;
}

.progress-metrics .ant-progress-inner {
  border-radius: 0px;
  border-style: solid;
  border-color: #010067;
  border-left: 0px;
  border-right: 0px;
  border-width: thin;
  background-color: #ff4e40;
}

.progress-metrics .ant-progress-success-bg {
  background-color: #0ace6a;
}

.progress-metrics .ant-progress-bg {
  background-color: #ffa400;
}

.ant-layout-header {
  height: 64px;
  padding: 0 24px;
  line-height: 64px;
  background: #001529;
  background-image: none;
  background-size: auto;
}

.ant-input::placeholder {
  color: #616369;
  opacity: 1;
}

.ant-card-grid:hover {
  box-shadow: 1px 0 0 0 #030075 inset, 0 1px 0 0 #030075 inset,
    0 0 1px 0 #030075 inset, 0 0 0 1px #030075 inset;
  -webkit-box-shadow: 1px 0 0 0 #030075 inset, 0 1px 0 0 #030075 inset,
    0 0 1px 0 #030075 inset, 0 0 0 1px #030075 inset;
}

.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.ant-input:focus,
.ant-input:hover {
  border-color: #030075;
  box-shadow: none;
}

.has-error .ant-input:focus {
  border-color: #fd0a40;
  box-shadow: none;
}

ant-card-head {
  border: none;
}

.ant-card-body {
  padding: 3px;
  border: none;
}

.ant-card-bordered {
  border: none;
  border-radius: 0;
}

/* collapse */
.ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding: 12px 40px;
}

.ant-btn:focus,
.ant-btn:hover {
  border: 1px solid #030075 !important;
  background-color: #fff !important;
  color: #000 !important;
}

.ant-modal-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  background-clip: padding-box;
  border: none;
  border-radius: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: auto;
}
