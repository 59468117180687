.dateFilterRow {
  display: flex;
  flex-direction: row;
  height: 54px;
  text-align: left;
}

.dateFilterColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.dateFilterLabel {
  font-size: 13px;
  text-align: left;
  font-weight: bold;
  font-family: "Avenir Heavy";
  color: black;
  margin-bottom: 4px;
}

.dateFilterButtonContainer {
  background-color: white;
  border-radius: 16px;
  text-align: center;
  margin-right: 8px;
  width: 81px;
  height: 32px;
  background-color: white;
  border: none;
  padding: 0;
  text-transform: uppercase;
}

.dateFilterButtonLabel {
  color: #030075;
  font-size: 12px;
  font: "Space Mono Regular";
}
