/* preview_title_desktop */
.preview_title {
  font-size: 20px;
  line-height: 1.3;
  font-family: arial, sans-serif;
  color: #1a0dab;
  display: inline-block;
  padding-top: 2px;
  margin-bottom: 3px;
}

.preview_title_desktop {
  width: 592px;
}

.preview_title_tablet {
  width: 700px;
}

.preview_title_ipad {
  width: 704px;
}

.preview_title_iphone {
  width: 311px;
  white-space: normal;
}

.breadcrums {
  font-size: 14px;
  padding-top: 1px;
  line-height: 1.3;
  color: #3c4043;

  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  font-family: arial, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
