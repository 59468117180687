.tile {
  width: 216px;
  height: 123px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff;
}

.titleContainer {
  width: 100%;
  height: 48px;
  background: url("../../assets/images/tile_title_background.png") repeat-x;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.title {
  font-size: 20px;
  font-family: "Avenir";
  color: #030075;
}

.valueContainer {
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  color: white;
  padding: 0px 13px;
  justify-content: space-between;
  align-items: center;
}

.value {
  height: 100%;
  font-family: "Space Mono";
  font-size: 36px;
  display: flex;
  align-items: center;
}

.description {
  width: 24px;
  height: 24px;
  background-color: #030075;
  display: flex;
  justify-content: center;
}

.checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid #030075;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 4px;
}

.checkboxIcon {
  width: 20px;
  height: 18px;
  background: url(../../assets/images/icon_checked.svg) no-repeat top left;
}
