.serp_simulator__remaining_text {
  font-size: 12px;
}

.serp_simulator__remaining_text--red-text {
  color: red;
}

.google-serp__form {
  padding: 5% 20%;
  background-color: #edf0f2;
}
